/* Colors */
.bg-primary {
  background-color: $color-workflow-primary !important;
}

.bg-light {
  background-color: $color-workflow-lightgrey !important;
}

.bg-dark {
  background-color: $color-workflow-darkgrey !important;
}

.bg-grey {
  background-color: $color-workflow-grey !important;
}

.bg-error {
  background-color: $color-workflow-error !important;
}

.bg-success {
  background-color: $color-workflow-success !important;
}

.color-success {
  color: $color-finxone-success !important;
}

.color-purple {
  color: $color-finxone-purple !important;
}

.bd-primary {
  border: 1px solid $color-workflow-primary !important;
}

.bd-light {
  border: 1px solid $color-workflow-lightgrey !important;
}

.bd-dark {
  border: 1px solid $color-workflow-darkgrey !important;
}

.bd-grey {
  border: 1px solid $color-workflow-grey !important;
}

.bd-error {
  border: 1px solid $color-workflow-error !important;
}

.bd-success {
  border: 1px solid $color-workflow-success !important;
}

.text {
  color: $color-workflow-text !important;
}

.text-primary {
  color: $color-workflow-primary !important;
}

.text-light {
  color: $color-workflow-lightgrey !important;
}

.text-dark {
  color: $color-workflow-darkgrey !important;
}

.text-grey {
  color: $color-workflow-grey !important;
}

.text-error {
  color: $color-workflow-error !important;
}

.text-success {
  color: $color-workflow-success !important;
}

.text-white {
  color: #fff !important;
}

/* Display */

.d-contents {
  display: contents;
}

.list-style-disc {
  ul {
    list-style: disc !important;
    li {
      display: list-item !important;
    }
  }
}

/* Position & alignment */
.pull-right,
.f-right {
  float: right !important;
}

.pull-left,
.f-left,
.float-left {
  float: left !important;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.align-items-center {
  align-items: center;
}

.align-items-flex-start {
  align-items: flex-start !important;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-italic {
  font-style: italic;
}

.is-full-screen {
  width: 100%;
  min-height: 100vh;
}

.h-webkit-fill-available {
  height: -moz-available !important; /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available !important; /* Mozilla-based browsers will ignore this. */
  height: stretch !important;
}

.is-full-height,
.h-100 {
  height: 100%;
}

.is-half-width,
.w-50 {
  width: 50% !important;
}
.is-full-width,
.w-100 {
  width: 100% !important;
}

.w-100vw {
  width: 100vw;
}

.w-fill-available {
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: stretch;
}

.is-vertical-align {
  display: flex;
  flex-direction: column;
}

.is-horizontal-align {
  display: flex;
  justify-content: center;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.space-evenly {
  justify-content: space-evenly;
}

.justify-content-end {
  justify-content: end;
}

.space-around {
  justify-content: space-around;
}

.is-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-no-wrap {
  text-wrap: nowrap;
}

.is-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
}

.is-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.is-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.align-self-center {
  align-self: center;
}

.flex-1 {
  flex: 1;
}

.ff-unset {
  flex-flow: unset;
}

.is-fixed {
  position: fixed;
  width: 100%;
}

/* Overflows/Scrolling */

.scrollable {
  overflow: scroll;
  height: -webkit-fill-available;
  height: -moz-fill-available;
  display: block;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.text-with-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

/* IMPORTANT when using this util class you have to add lang="de" (eg. <div lang="de" class="hypen-wrap">Your text to hyphenate</div>)
 to the element you're adding it to*/
.hyphen-wrap {
  hyphens: auto;
}

/* Misc */

.word-break-all {
  word-break: break-all;
}

.is-rounded {
  border-radius: 100%;
}

.clearfix {
  content: '';
  display: table;
  clear: both;
}

.is-hidden,
.d-none {
  display: none !important;
}

.no-wrap {
  flex-wrap: nowrap;
}

.wrap {
  flex-wrap: wrap;
}

.ac-center {
  align-content: center;
}

.whitespace-no-wrap {
  white-space: nowrap;
}

.img-fluid {
  height: 100%;
  max-width: 100%;
}

.position-relative {
  position: relative;
}

.cursor-pointer,
.is-pointer {
  cursor: pointer !important;
}

.position-absolute-fill {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.position-absolute-fill-left-right {
  position: absolute;
  left: 0;
  right: 0;
}

.position-absolute {
  position: absolute;
}

.is-at-front {
  z-index: 1;
}
.z-index-2 {
  z-index: 2;
}

.d-flex {
  display: flex;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-inline-flex {
  display: inline-flex;
  align-items: center;
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.dashed-border {
  background-image: linear-gradient(to right, var(--border-color-1) 33%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 15px 1px;
  background-repeat: repeat-x;
  height: 1px;
}


/* hiding classes */

@media screen and (max-width: 599px) {
  .hide-xs {
    display: none !important;
  }
}

@media screen and (min-width: 600px) and (max-width: 899px) {
  .hide-sm {
    display: none !important;
  }
}

@media screen and (min-width: 900px) and (max-width: 1199px) {
  .hide-md {
    display: none !important;
  }
}

@media screen and (min-width: 1200px) {
  .hide-lg {
    display: none !important;
  }
}

@media print {
  .hide-pr {
    display: none !important;
  }
}

.visibility-hidden {
  visibility: hidden;
}

.overflow-hidden {
  overflow: hidden !important;
}

@for $i from 1 to 9 {
  //z-index for items in flex
  .z-index-#{$i} {
    z-index: $i !important;
  }
}

@for $i from 1 to 10 {
  //opacity for items
  .opacity-#{$i} {
    opacity: $i * .1 !important;
  }
}

.shadow-none {
  box-shadow: none !important;
}

.background-transparent {
  background: transparent !important;
}

.ellipsis-line-clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
} 